<template>
    <div>
      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <b-row v-if="role.roles.some( el => el['name'] === 'information_manager' )" class="p-2">
        <b-col
          md="6"
          xl="3"
        >
          <validation-provider
            #default="{ errors }"
            name="service"
            rules="required"
          >
            <b-form-group
              label-for="service"
              :state="errors.length > 0 ? false : null"
            >
        
              <label> الخدمة</label>
              <v-select
                v-model="MainserviceTransfer"
                :reduce="(val) => val.value"
                :options="optionService"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <!-- <b-col
          md="6"
          xl="3"
        >
          <validation-provider
            #default="{ errors }"
            name="documentType"
            rules="required"
          >
            <b-form-group
              label-for="cardNumber"
              :state="errors.length > 0 ? false : null"
            >
              <label> الخدمة الفرعية </label>
              <v-select
                v-model="main_service_id"
                :reduce="(val) => val.value"
                :options="optionSubServices"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col> -->
   
    
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>نوع التحويل</label>
           
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="TransferType"
              :options="TransferTypeOptions"
              class="w-100"
              :reduce="(val) => val.value"

            />
          </b-col>
        </b-row>
 
        <b-table
          ref="reftransferListTable"
          class="position-relative"
          :items="fetchListServices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          
          
        <template #cell(approving_stage)="data">
          <div class="row">
<label  v-if="data.item.approving_stage=='source_coordinator' "> الخدمة</label>
          <v-select
          v-if="data.item.approving_stage=='source_coordinator' "
            v-model="data.item.to_main_service_id"
            :options="subServiceOption"

            label="name"

          />
    
          <label  v-if="data.item.approving_stage=='destination_coordinator' "> الموظف</label>
      
          <v-select
          v-if="data.item.approving_stage=='destination_coordinator' "
            v-model="data.item.case_manager_id"
            :options="employOption"

            label="first_name"

          />
        </div>
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.type=='assign'"> تحويل</span>
          <span v-else> تحويل اخر</span>
        </template>
        <template #cell(actions)="data">
          
          <b-button 
          variant="primary"
          v-if="data.item.approving_stage=='source_coordinator'"
            @click="TransferTo(data.item.id,data.item.to_main_service_id)">
            <feather-icon icon="CheckIcon" />
<span class="align-middle ml-50"> تحويل </span>

          </b-button>
          <b-button 
          variant="primary"
          v-if="data.item.approving_stage=='destination_coordinator'"
            @click="TransferToCaseManager(data.item.id,data.item.case_manager_id)">
            <feather-icon icon="CheckIcon" />
<span class="align-middle ml-50"> تحويل </span>

          </b-button>
        
</template>
       
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BCardHeader,
    BCardBody,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { ref, onUnmounted } from "@vue/composition-api";
  import { avatarText } from "@core/utils/filter";
  
  import useRejectedTransfer from "./useRejectedTransfer";
  import { max } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import servicesTransferStoreModule from "./servicesTransferStoreModule";
  import { jsontoexcel } from "vue-table-to-excel";
  export default {
    setup() {
      const Services_APP_STORE_MODULE_NAME = "app-services-transfer";
  
      // Register module
      if (!store.hasModule(Services_APP_STORE_MODULE_NAME))
        store.registerModule(Services_APP_STORE_MODULE_NAME, servicesTransferStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(Services_APP_STORE_MODULE_NAME))
          store.unregisterModule(Services_APP_STORE_MODULE_NAME);
      });

      //    }
      const subServiceOption = ref([]);
const employOption=ref([])

     const getSubService = (id) => {
        //console.log(id)

      store.dispatch("app-services/SubService",id).then((response) => {

        subServiceOption.value = response.data.data;

      });

    };
    const TransferTypeOptions=ref([{
    label:'صادرة',
    value:'outgoing'
},
{
    label:'واردة',
    value:'incoming'
}
])
    const getEmploy = (id) => {
        //console.log(id)

      store.dispatch("app-services/getEmployy",id).then((response) => {
        //console.log('em',response)
        employOption.value = response.data;

      });

    };
    const serviceId = localStorage.getItem("roleService");

    const role = JSON.parse(localStorage.getItem("userData"));
    //console.log(role)
            if(serviceId){
            getSubService(serviceId)
            getEmploy(serviceId)

        }
     
  
      const {
        fetchListServices,
        tableColumns,
  
        perPage,
        currentPage,
        totalUsers,
        mainService,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        reftransferListTable,
        refetchData,
        TransferType,
        MainserviceTransfer
      
  
  
      } = useRejectedTransfer();
  
      return {
        MainserviceTransfer,
        fetchListServices,
        tableColumns,
        TransferType,
        subServiceOption,
        TransferTypeOptions,
        employOption,
        getSubService,
        getEmploy,
        perPage,
        serviceId,
        role,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        reftransferListTable,
        refetchData,
      };
    },
    components: {
     
      flatPickr,
      BButton,
      max,
      BDropdownItemButton,
      BFormInvalidFeedback,
      ValidationProvider,
      ValidationObserver,
      BCard,
      BRow,
      BCol,
      BFormInput,
  
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BCardHeader,
      BCardBody,
  
      vSelect,
    },
    data() {
    return {
      notes: '',
      optionService: [],
      proposal_service_id: '',
     
      // Mainservice: '',
      optionSubServices: [],
    }
  },
  created() {
    this.getServiceType()
  },
  watch: {
    MainserviceTransfer() {
      this.fetchListServices()
    },
  
  },

    methods:{
      getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        proposal_services.forEach(el => {
          this.optionService.push({ label: el.name, value: el.id })
        })
      })
    },
      TransferTo(id,details){
        //console.log(details)
        if (details=='' || !details ){
          this.$swal({
              title: "",
              text: `يجب اختيار تحويل الى`,
              icon: "info",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
        }
        else{
        let data={
          to_main_service_id:details.main_service_id,

          }
        this.$http.post(`/api/v1/service_referrals/${id}/forward`,data).then(res=>{
            //console.log(res)
            this.refetchData()
              this.$swal({
              title: "تم التحويل بنجاح",
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
        }).catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })})
          }
    },
    TransferToCaseManager(id,details){
        //console.log(details)
        if (details=='' || !details ){
          this.$swal({
              title: "",
              text: `يجب اختيار تحويل الى`,
              icon: "info",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
        }
        else{
        let data={
          case_manager_id:details.id,

          }
        this.$http.post(`/api/v1/service_referrals/${id}/forward`,data).then(res=>{
            //console.log(res)
            this.refetchData()
              this.$swal({
              title: "تم التحويل بنجاح",
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
        }).catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })})
          }
    },
    }

  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  <style scoped>
  div {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .media {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  