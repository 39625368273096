<template>

  <b-tabs>

    <!-- Tab: Account -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">طلبات التحويل الجديدة</span>
      </template>
      <transfer-list />

    </b-tab>

    <!-- Tab: Information -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="FileIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">الطلبات التحويل المقبولة </span>
      </template>
      <accepted-transfer />
    </b-tab>

    <!-- Tab: Social -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">لطلبات التحويل المرفوضة</span>
      </template>
      <rejected-transfer />
    </b-tab>
  </b-tabs>

</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import AcceptedTransfer from './AcceptedTransfer'

import transferList from '../services-beneficiary-list/transferList'
import RejectedTransfer from './RejectedTransfer.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    transferList,

    AcceptedTransfer,
    RejectedTransfer,
  },
  setup() {
    const userData = ref(null)

    return {
      userData,
    }
  },
}
</script>

  <style>

.position-relative.h-100.table-responsive {
    min-height: 500px !important;
}
</style>
