<template>
  <div>
          <b-modal
          :ref="`my-modal`"
          centered
          ok-only
          ok-title="حسنا"
          cancel-variant="outline-secondary"
          title-tag="div"
        >

<b-row>
  <b-col md="12"  >
    
<h6>الملاحظة: </h6>
<p >{{data}}</p>
  </b-col>

</b-row>
          
        </b-modal>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-row class="p-2">
        <b-col
          md="6"
          xl="3"
          v-if="role.roles.some( el => el['name'] === 'information_manager' )" 
        >
          <validation-provider
            #default="{ errors }"
            name="service"
            rules="required"
          >
            <b-form-group
              label-for="service"
              :state="errors.length > 0 ? false : null"
            >
          
              <label> الخدمة</label>
              <v-select
                v-model="MainserviceTransfer"
                :reduce="(val) => val.value"
                :options="optionService"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <!-- <b-col
          md="6"
          xl="3"
        >
          <validation-provider
            #default="{ errors }"
            name="documentType"
            rules="required"
          >
            <b-form-group
              label-for="cardNumber"
              :state="errors.length > 0 ? false : null"
            >
              <label> الخدمة الفرعية </label>
              <v-select
                v-model="main_service_id"
                :reduce="(val) => val.value"
                :options="optionSubServices"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col> -->
   
   
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>نوع التحويل</label>

          <v-select
            v-model="TransferType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="TransferTypeOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
    
    </b-row>
      <b-table
        ref="reftransferListTable"
        class="position-relative h-100"
        :items="fetchListServices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->

        <!-- Column: Role -->

        <!-- Column: Status -->
        <!-- Column: Status -->

        <!-- Column: Actions -->
        <!-- <template #cell(mainservice)="data" >
            <v-select
              v-model="data.item.mainService"
              :options="mainServiceOption"
              :reduce="(val) => val.id"
              @inpost="getSubService(data.item.mainService)"

              label="name"

            />
          </template>

        -->
        <template #cell(proposal_services.name)="data">
          <v-select
            v-if="
              TransferType == 'incoming' &&
                role.roles.some((el) => el['name'] === 'coordinator')
            "
            v-model="data.item.subservice"
            :options="subServiceOption"
            :reduce="(val) => val.id"
            label="name"
          />
          <span v-else>{{ data.item.proposal_services.name }} </span>
        </template>
        <!-- && data.item.approving_stage=='destination_coordinator' -->
        <template #cell(created_by)="data">
          <v-select
            v-if="
              TransferType == 'incoming' &&
                role.roles.some((el) => el['name'] === 'coordinator')
            "
            v-model="data.item.employee"
            :reduce="(val) => val.id"
            :options="employOption"
            label="first_name"
          />
          <span v-else> {{data.item.created_by}}</span>
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.type == 'assign'"> تحويل</span>
          <span v-else> تحويل اخر</span>
        </template>

        <template #cell(notes)="data">
          <div class="d-flex justify-content-center">
        <feather-icon icon="EyeIcon" class="mr-50" @click="showEmpModal(data.item.notes)" />
    </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                TransferType == 'incoming' &&
                  role.roles.some((el) => el['name'] === 'coordinator')
              "
              @click="
                AcceptByCoordinator(
                  data.item.id,
                  data.item.employee,
                  data.item.subservice,
                  data.item.notes
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50"> موافق </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                TransferType == 'outgoing' &&
                  role.roles.some((el) => el['name'] === 'coordinator')
              "
              @click="AcceptByCoordinatorOutgoin(data.item.id, data.item.notes)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50"> موافق </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                TransferType == 'incoming' &&
                  role.roles.some((el) => el['name'] === 'case_manager')
              "
              @click="AcceptByCoordinatorOutgoin(data.item.id, data.item.notes)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50"> موافق </span>
            </b-dropdown-item>

            <b-dropdown-item @click="Decline(data.item.id)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50"> رفض </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <rejecte-reason
        ref="childComponent"
        @fetchData="refetchData"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BFormTextarea,
  BCardBody,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { jsontoexcel } from 'vue-table-to-excel'
import ServicesListFilters from './ServicesListFilters.vue'
import useServicesList from './useServiceTransfer'
import servicesStoreModule from './servicesStoreModule'
import store from '@/store'
import RejecteReason from '../ServiceTransfer/RejecteReason.vue'

export default {
  setup() {
    const Services_APP_STORE_MODULE_NAME = 'app-services'

    // Register module
    if (!store.hasModule(Services_APP_STORE_MODULE_NAME)) { store.registerModule(Services_APP_STORE_MODULE_NAME, servicesStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Services_APP_STORE_MODULE_NAME)) { store.unregisterModule(Services_APP_STORE_MODULE_NAME) }
    })
    const subServiceId = ref('')

    const TransferTypeOptions = ref([
      {
        label: 'صادرة',
        value: 'outgoing',
      },
      {
        label: 'واردة',
        value: 'incoming',
      },
    ])

    const subServiceOption = ref([])
    const employOption = ref([])

    const getSubService = id => {
      // console.log(id)

      store.dispatch('app-services/SubService', id).then(response => {
        subServiceOption.value = response.data.data
      })
    }
    const getEmployy = id => {
      // console.log(id)

      store.dispatch('app-services/getEmployy', id).then(response => {
       //  console.log('em',response.data)
        employOption.value = response.data
      })
    }
    const serviceId = localStorage.getItem('roleService')

    const role = JSON.parse(localStorage.getItem('userData'))
    // console.log(role)
    if (serviceId) {
      getSubService(serviceId)
      getEmployy(serviceId)
    }
    const MainService = () => {
      store.dispatch('app-services/GetMainService').then(response => {
        // console.log(response)
        mainServiceOption.value = response
      })
    }
    MainService()

    const {
      fetchListServices,
      tableColumns,

      perPage,
      currentPage,
      totalUsers,
      mainService,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,
      mainservice,
      mainServiceOption,
      TransferType,
      MainserviceTransfer,
    } = useServicesList()

    return {
      MainserviceTransfer,
      mainservice,
      role,
      mainServiceOption,
      subServiceId,
      MainService,
      mainService,
      fetchListServices,
      tableColumns,
      getEmployy,
      getSubService,
      subServiceOption,
      serviceId,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,
      TransferTypeOptions,
      TransferType,
      employOption,
    }
  },
  components: {
    ServicesListFilters,
    flatPickr,
    BFormTextarea,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    RejecteReason,
  },
  data() {
    return {
      notes: '',
      data:'',
      optionService: [],
      proposal_service_id: '',
     
      // Mainservice: '',
      optionSubServices: [],
    }
  },
  watch: {
    MainserviceTransfer() {
      this.fetchListServices()
    },
  
  },
  created() {
    this.getServiceType()
  },
  methods: {
    showEmpModal(notes) {
    
    this.data = notes;
    this.$refs["my-modal"].show();
  },
    getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        proposal_services.forEach(el => {
          this.optionService.push({ label: el.name, value: el.id })
        })
      })
    },
    Accept(id, subService) {
      // console.log(subService.id)
      const data = {
        proposal_service_id: subService.id,
      }
      this.$http
        .post(`/api/v1/service_referrals/${id}/approve`, data)
        .then(res => {
          // console.log(res)
          this.refetchData()
          this.$swal({
            title: 'تم التحويل للحالة بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    AcceptByCoordinatorOutgoin(id, details) {
      const data = {
        details,
      }
      this.$http
        .post(`/api/v1/service_referrals/${id}/approve`, data)
        .then(res => {
          // console.log(res)
          this.refetchData()
          this.$swal({
            title: 'تم الموافقة بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    AcceptByCoordinator(id, case_manager_id, to_main_service_id, details) {
      let data = null

      if (
        this.TransferType == 'incoming'
        && this.role.roles.some(el => el.name === 'coordinator')
      ) {
        data = {
          case_manager_id,
          proposal_service_id: to_main_service_id,
          details,
        }
      }

      this.$http
        .post(`/api/v1/service_referrals/${id}/approve`, data)
        .then(res => {
          // console.log(res)
          this.refetchData()
          this.$swal({
            title: 'تم الموافقة بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    Decline(id) {
      this.$refs.childComponent.show(id)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
